import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export default function Footer() {
  return (
    <Box sx={{ pb: 3 }}>
      <Typography component="div" sx={{ textAlign: 'center', opacity: 0.5 }}>
        &#169; {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}