import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import logo from '../images/ilum-labs-logo-white.png';


export default function TopBar() {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          background: "#121212",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          boxShadow: "none",
          px: isLg ? 0 : 10
        }}>
        <Toolbar>
          <Link href="/" underline="none" sx={{display:'flex', alignItems: 'center'}}>
            <img src={logo} alt="logo" style={{height: 32, marginRight: 10, display: 'inline-block'}}/>
            <Typography variant="h6" component="span" sx={{ color: "#fff"}}>
              ilum labs
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Link href="https://www.linkedin.com/in/devonbernard/" underline="none">
            <Button color="inherit" sx={{py:2, color: "#fff"}}>Contact</Button>
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}