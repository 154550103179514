import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import TopBar from './components/topbar';
import ProjectsSection from './components/projects';
import Footer from './components/footer';


import './App.css';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <TopBar />
      
      <Box sx={{pt:8, pb: 8}}>
        <ProjectsSection />
      </Box>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
