import React from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import aceImage from '../images/ace-2.png';
import smImage from '../images/solminting.png';
import tweetDaoImage from '../images/tweetdao.png';
import zeroxMeImage from '../images/0xme.png';

// Colors: #00b4be, #008a5a

// AC Exchange, Solminting, TweetDao, 0x?,
// ??? LI Scaper, PunHunt, DbPanel, RadBox, SolArt, BitWatch
const projects = [
  {
    title: 'AC Exchange',
    description: 'Social network for Nintendo gamers\n500k+ users',
    url: 'https://apps.apple.com/us/app/animal-crossing-nh-exchange/id1506453425?platform=iphone',
    backgroundImageUrl: aceImage,
    acquired: true,
    attributes: ['social','mobile','gaming']
  },
  {
    title: 'SolMinting',
    description: 'NFT minting with zero code\n$4m in client sales',
    url: 'https://solminting.com',
    backgroundImageUrl: smImage,
    acquired: false,
    attributes: ['web3','solana','NFTs', 'no code']
  },
  {
    title: 'TweetDAO',
    description: 'Decentralized governance of Twitter accounts',
    url: 'https://twitter.com/tweet_dao',
    backgroundImageUrl: tweetDaoImage,
    acquired: false,
    attributes: ['web3','NFTs','DAOs']
  },
  {
    title: '0x.me',
    description: 'Decentralized profiles and link sharing',
    url: 'https://0x.me',
    backgroundImageUrl: zeroxMeImage,
    acquired: false,
    attributes: ['web3','solana', 'social']
  },
  // {
  //   title: 'DB Panel',
  //   description: 'No code database management system',
  //   url: null,
  //   backgroundImageUrl: galaxy,
  //   acquired: false,
  //   inactive: true,
  //   attributes: ['no code', 'dev tools']
  // },
  // {
  //   title: 'Rad Box',
  //   description: 'Radiologist education and testing',
  //   url: null,
  //   backgroundImageUrl: radBoxImage,
  //   acquired: false,
  //   inactive: true,
  //   attributes: ['healthcare','AI', 'deep learning']
  // }
];

function ProjectCard({ project }) {
  const theme = useTheme();
  const isMobile = false; //useMediaQuery(theme.breakpoints.down('md'));
  const isLgLower = useMediaQuery(theme.breakpoints.down('lg'));

  const content = (
    <>
      <Typography
        sx={{
          fontSize: isLgLower ? 40 : 72,
          lineHeight: isLgLower ? '48px' : '80px',
          mt:'auto'
        }}
      >{project.title}</Typography>
      <Typography
        sx={{
          fontSize: isLgLower ? 20 : 30,
          lineHeight: isLgLower ? '26px' : '36px',
          whiteSpace: 'pre-wrap'
        }}
      >{project.description}</Typography>
      <Box sx={{pt:2, mt: '-16px'}}>
        {project.attributes.map(attr =>
          <Box
            key={attr}
            sx={{
              display: 'inline-block',
              mr:2, px:'12px',
              background: '#008a5a',
              borderRadius: '16px',
              mt: '16px'
            }}
          >{attr}</Box>
        )}
      </Box>
    </>
  );
  return (
    <Grid
      item xs={12} md={6}
      sx={{
        mb: isMobile ? 3 : 0,
        pt: 3,
        px: isMobile ? '0px !important' : 3
      }}
    >
      {isMobile && 
        <Box sx={{px: 3}}>{content}</Box>
      }
      <Link href={project.url || '#'} underline="none">
        <Grid container spacing={0} direction="column"
          sx={{
            aspectRatio: '1 / 1',
            position: 'relative',
            background: `url(${project.backgroundImageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: '50% 50%',
            borderRadius: 4,
            color: '#fff',
            p: 3,
            cursor: project.url ? 'pointer' : 'inherit',          
          }}
        >
          {project.acquired &&
            <Box sx={{
              position: 'absolute',
              mt: 1, right: 0,
              background: '#008a5a', ml:'auto', px:2, py: '6px',
              zIndex:2,
              fontSize: 14,
              borderRadius: '16px 0 0 16px',
            }}>ACQUIRED</Box>
          }
          {project.inactive &&
            <Box sx={{
              position: 'absolute',
              mt: 1, right: 0,
              background: '#555',
              opacity: 0.5,
              ml:'auto', px:2, py: '6px',
              zIndex:2,
              fontSize: 14,
              borderRadius: '16px 0 0 16px',
            }}>INACTIVE</Box>
          }
          {!isMobile && content}
        </Grid>
      </Link>
    </Grid>
  );
}

export default function ProjectsSection() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down('sm'));
  const isLg = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <>
      <Typography variant="h4" align='center'
        sx={{py: isSm ? 4 : 4}}
      >
        Our Projects
      </Typography>
      <Grid container spacing={3} sx={{mx:0, px: isLg ? 0 : 10, maxWidth: '100vw'}}>
        {projects.map(project => 
          <ProjectCard project={project} key={project.title} />
        )}
      </Grid>
    </>
  );
}